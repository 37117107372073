





































import {numberAsFileSize} from '@labor-digital/helferlein/lib/FormatAndConvert/numberAsFileSize';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'DownloadList',
    components: {LinkWrap},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            headline: this.context.data.get('headline') ?? this.$t('downloadList.headline')
        };
    },
    computed: {
        files(): Array<PlainObject>
        {
            return this.context.data.get('files', []);
        }
    },
    methods: {
        formatFileSize(size)
        {
            return numberAsFileSize(size);
        }
    },
    mounted()
    {
        console.log(this.context.data);
    }
};
