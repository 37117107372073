var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "downloadList" }, [
    _c("h3", {
      staticClass: "downloadList__headline",
      domProps: { innerHTML: _vm._s(_vm.headline) },
    }),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "downloadList__list" },
      _vm._l(_vm.files, function (item) {
        return _c(
          "li",
          { staticClass: "downloadList__item" },
          [
            item.file !== null
              ? _c("link-wrap", { attrs: { link: item.file.url } }, [
                  item.label !== ""
                    ? _c("span", [_vm._v(_vm._s(item.label))])
                    : item.file.image.title !== ""
                    ? _c("span", [_vm._v(_vm._s(item.file.image.title))])
                    : _c("span", [_vm._v(_vm._s(item.file.filename))]),
                  _vm._v(" "),
                  _c("span", { staticClass: "downloadList__item--meta" }, [
                    _vm._v(
                      "\n                    (" +
                        _vm._s(item.file.extension) +
                        " " +
                        _vm._s(_vm.formatFileSize(item.file.size)) +
                        ")\n                "
                    ),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }